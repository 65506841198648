document.addEventListener("DOMContentLoaded", function () {
  // ==========================================================================
  // ページトップボタンの表示切り替え
  // ==========================================================================
  const pageTopButton = document.querySelector(".c-pagetop");

  window.addEventListener("scroll", function () {
    if (window.scrollY > 100) {
      pageTopButton.classList.add("is-view");
    } else {
      pageTopButton.classList.remove("is-view");
    }
  });
});
